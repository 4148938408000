.input-text{
    width: 80%;
    padding: 5px 10px;
    border-radius: 2px;
    border: none;
    outline: none;
    font-family: 'Nunito', sans-serif;
    color: $color-text;

    &:focus{
        outline: 3px solid $color-border;
    }
}