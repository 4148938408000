.card {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px 5px;
    box-shadow: 2px 2px 2px 3px rgba($color: #000000, $alpha: 0.5);
    transition: all ease 0.5s;
}

.card--click {
    cursor: pointer;

    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.5);
        color: #fff;
        .card-img img {
            width: 120%;
        }
    }
}

.card__size--small {
    width: 250px;
    height: 375px;
}

.card__size--big {
    width: 90%;
    height: 60vh;
}

.card-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .card-nombre {
        width: 100%;
        padding: 0px 10px;
        text-align: center;
        text-shadow: 1px 1px rgba($color: #000000, $alpha: 0.3);
    }

    .card-descripcion {
        width: 100%;
        text-align: right;
        padding-right: 20px;
        hyphens: auto;
    }

    .card-precio {
        text-align: right;
        width: 100%;
        padding-right: 20px;
        text-shadow: 1px 1px rgba($color: #000000, $alpha: 0.3);
    }
    hr {
        width: 100%;
    }
}

.card-content--big {
    width: 50%;
}

.card-nombre--big {
    text-align: right;
}

.card-img {
    overflow: hidden;
    position: relative;

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease 0.5s;
    }
}

.card-img--small {
    width: 100%;
    height: 50%;
}

.card-img--big {
    padding-left: 20px;
    width: 50%;
    height: 100%;
}

.card__disponible{
    color: rgb(5, 167, 46);
}

.card__no-disponible{
    color: rgb(255, 51, 0);
}