.footer {
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    hr {
        width: 90%;
        color: #fff;
        margin-bottom: 10px;
    }
}

.footer-text {
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    text-shadow: 2px 2px rgba($color: #000000, $alpha: 0.8);
    padding: 0px 10px;

    a{
        color: white;
        transition: color ease 0.3s;
        &:hover{
            color: lighten($color: $color-border, $amount: 20);
        }
    }
}
