$color-text: #3b3b3b;
$color-border: #b40c68;

.mt-1{
    margin-top: 5px;
}

.mt-2{
    margin-top: 10px;
}

.mt-3{
    margin-top: 20px;
}

.mb-1{
    margin-bottom: 5px;
}

.mb-2{
    margin-bottom: 10px;
}

.mb-3{
    margin-bottom: 20px;
}

.ml-1{
    margin-left: 5px;
}

.ml-2{
    margin-left: 10px;
}

.ml-3{
    margin-left: 20px;
}

.mr-1{
    margin-right: 5px;
}

.mr-2{
    margin-right: 10px;
}

.mr-3{
    margin-right: 20px;
}

.text-shadow{
    text-shadow: 3px 3px rgba($color: #000000, $alpha: 0.5);
}

.box-shadow{
    box-shadow: 2px 2px 0px 3px rgba($color: #000000, $alpha: 0.3);
}