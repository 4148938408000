.header {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.header-img {
    width: 100%;
}

.header-texto {
    top: 50%;
    width: 50%;
    margin-top: 0;
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h1 {
        font-size: 4rem;
        color: #fff;
    }
}
