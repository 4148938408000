.nav__btn{
    margin-right: 15px;
    cursor: pointer;
    transition: color 0.3s ease;
    border-radius: 5px;
    width: 3rem;
    height: 2.2rem;
    position: relative;
    display: none;//defecto debe estar en none

    &:hover .bar{
        background-color: $color-border;
    }

    &:active .bar{
        background-color: darken($color: $color-border, $amount: 20%);
    }
}

.bar{
    width: 100%;
    height: 0.4rem;
    position: absolute;
    background-color: white;
    border-radius: 3px;
    z-index: -1;
    transition: all 0.5s ease;
}

.bar-1{
    top: 0;
    transform: translateY(-50%);
}

.bar-1--active{
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
}

.bar-2{
    top: 50%;
    transform: translateY(-50%);
}

.bar-2--active{
    opacity: 0;
}

.bar-3{
    top: 100%;
    transform: translateY(-50%);
}

.bar-3--active{
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
}

